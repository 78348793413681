<template>
  <div>
    <v-layout class="p-4 pr-0 border-bottom-light-grey min-height-57px">
      <v-flex class="d-flex font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <span class="svg-icon svg-icon-lg mr-2 svg-v-list-icon">
            <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
          </span>
          <!--end::Svg Icon-->
        </span>
        <h1 class="form-title font-size-20 font-weight-700 text-capitalize">
          <template v-if="typeText">
            {{ typeText }}
          </template>
          <template v-else> Files </template>
        </h1>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="mx-2 mb-1 custom-bold-button white--text"
          color="cyan"
          @click="attachmentDialog = true"
          >Add Attachment</v-btn
        >
      </v-flex>
    </v-layout>
    <div class="overflow-y">
      <v-row>
        <template v-if="dbFiles.length">
          <v-col
            md="12"
            v-for="(row, index) in dbFiles"
            :key="index"
            class="border-bottom d-flex"
            :class="{
              'custom-border-top': index == 0,
              'orange_lighten-5': index % 2 == 0,
            }"
          >
            <div class="px-4" style="width: 60px">
              <template v-if="row.extension && isImage(row.extension)">
                <ImageTemplate
                  :src="row.file.url"
                  style="max-width: 50px; width: 50px"
                ></ImageTemplate>
              </template>
              <template v-else>
                <inline-svg
                  style="max-width: 50px; width: 50px"
                  :src="$assetURL(`media/mime/${row.extension}.svg`)"
                />
              </template>
            </div>
            <div style="width: 70%" class="px-4 text-truncate">
              <b
                class="blue--text text--darken-4 cursor-pointer"
                @click="doAction(row, 'download')"
              >
                <template v-if="row.name">
                  {{ row?.name }}
                </template>
                <template v-else>
                  {{ row?.file?.name }}
                </template>
              </b>
              <div>
                <div class="d-flex align-center">
                  <div style="width: 30%">
                    <b>Size : </b
                    >{{ Number(Number(row.size) / 1024).toFixed(2) }}KB
                  </div>
                  <div style="width: 70%">
                    <b>Show in Tablet : </b
                    >{{ row.show_in_mobile_app ? "Yes" : "No" }}
                  </div>
                </div>
              </div>
              <div class="d-flex align-center py-1">
                <v-chip
                  outlined
                  text-color=""
                  label
                  small
                  class="text-uppercase"
                  >{{ row.module }}</v-chip
                >
                <template v-if="row[row?.module]?.id">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <Barcode
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="ml-2"
                        :route="row.route"
                        :barcode="row[row.module].barcode"
                        :id="row[row.module].id"
                      />
                    </template>
                    <span>{{ row[row.module].title }}</span>
                  </v-tooltip>
                </template>
              </div>
              <div>
                <v-chip
                  v-if="documentType == 302 && row?.signature_sdo_type == 1"
                  color="red darken-4 white--text"
                  label
                  small
                  class="text-uppercase"
                  >Signed by Customer</v-chip
                >
              </div>
            </div>
            <div style="width: 20%" class="px-4 d-flex flex-wrap">
              <div class="mr-0">
                <div>
                  <b class="d-inline-block" style="width: 80px">Created At</b
                  >:&nbsp;&nbsp;
                  <span v-if="row.added_at">
                    {{ formatDateTime(row.added_at) }}
                  </span>
                  <em class="text-muted" v-else> no created </em>
                </div>
                <div>
                  <b class="d-inline-block" style="width: 80px">Created by </b
                  >:&nbsp;&nbsp;
                  <span v-if="row.added_by?.display_name">
                    {{ row.added_by.display_name }}
                  </span>
                  <em class="text-muted" v-else> no Created by </em>
                </div>
              </div>
            </div>
            <div style="width: 10%" class="px-4 d-flex">
              <v-spacer></v-spacer>
              <v-btn
                @click="doAction(row, 'download')"
                small
                icon
                depressed
                color="blue darken-4"
                class="mx-1"
              >
                <v-icon medium>mdi-download</v-icon>
              </v-btn>
              <v-btn
                @click="doAction(row, 'delete')"
                icon
                small
                depressed
                class="mx-1"
                color="red lighten-1"
                ><v-icon medium>mdi-delete</v-icon></v-btn
              >
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-col class="text-center border-top">
            <p class="m-0 row-not-found text-center" style="color: #f7941e">
              <img
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image"
              />
              Uhh... There are no file at the moment.
            </p>
          </v-col>
        </template>
      </v-row>
    </div>
    <Dialog :commonDialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete File</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p my-0">
              Are you sure you want to delete,
              <span class="font-weight-600">{{
                deleteRow?.name ? deleteRow?.name : deleteRow?.file?.name
              }}</span>
              ?
            </p>
            <br />
            <span>Note: Deleting File is irreversible</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          depressed
          tile
          :disabled="deleteLoading"
          v-on:click="deleteDialog = false"
        >
          No, Close
        </v-btn>
      </template>
    </Dialog>
    <DocumentwithName
      :document-dialog="attachmentDialog"
      v-on:close="attachmentDialog = false"
      :document-type="documentType"
      :document-id="typeId"
      :module="attachmentModule"
      :module-id="typeId"
      :need-cost="needCost"
      module-action="attach from detail"
      v-on:success="getAttachments()"
    />
  </div>
</template>
<script>
import Barcode from "@/view/pages/partials/Barcode.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ImageTemplate from "@/view/pages/Image";
import DocumentwithName from "@/view/pages/DocumentwithName";
import { QUERY, DELETE } from "@/core/services/store/request.module";
import { toNumber, round, find } from "lodash";

export default {
  mixins: [CommonMixin],
  props: {
    typeText: {
      type: String,
      default: null,
    },
    needCost: {
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    attachmentModule: {
      type: String,
      default: "ticket",
    },
    documentType: {
      type: Number,
      default: null,
    },
    typeId: {
      type: Number,
      default: null,
    },
  },
  watch: {},
  data() {
    return {
      attachmentDialog: false,
      pageLoading: true,
      deleteLoading: false,
      deleteRow: null,
      deleteDialog: false,
      attachDialog: false,
      attachLoading: false,
      files: [],
      dbFiles: [],
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
    };
  },
  methods: {
    deleteFile() {
      if (!this.deleteRow?.id) {
        return false;
      }

      this.deleteLoading = true;
      this.$store
        .dispatch(DELETE, {
          url: `delete-documents/${this.deleteRow.id}`,
        })
        .then(() => {
          this.deleteRow = null;
          this.deleteDialog = false;
          this.getAttachments();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    doAction(row, param) {
      switch (param) {
        case "download":
          window.open(row.file.url, "_blank");
          break;
        case "delete":
          this.deleteRow = row;
          this.deleteDialog = true;
          break;
      }
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    getFileSize(size) {
      return round(toNumber(size) / 1024, 2);
    },
    getAttachments() {
      this.$store
        .dispatch(QUERY, {
          url: "attachments",
          data: {
            primary: this.type,
            primary_id: this.typeId,
          },
        })
        .then(({ data }) => {
          this.dbFiles = data;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
  },
  components: {
    Dialog,
    Barcode,
    ImageTemplate,
    DocumentwithName,
  },
  mounted() {
    this.getAttachments();
  },
};
</script>
