<template>
  <div class="ticket-closer-detail" v-if="getPermission('closer:view')">
    <v-container fluid class="custom-bthrust-style px-0">
      <v-container>
        <v-layout class="justify-end mb-1">
          <div class="mr-2">
            <v-text-field
              v-model.trim="filter.search"
              v-on:click:clear="clearFilter('search')"
              clearable
              dense
              filled
              flat
              label="Search"
              hide-details
              solo
              :disabled="closerLoading"
              color="cyan"
              @keydown.enter="getTicketCloser"
              @keydown.tab="getTicketCloser"
            ></v-text-field>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-autocomplete
              v-model.trim="filter.visit"
              hide-details
              :items="visitList"
              clearable
              dense
              flat
              filled
              label="Visit"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="closerLoading"
              item-text="barcode"
              item-value="id"
              v-on:change="getTicketCloser"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Visit(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                  {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                  {{ formatTime(item.finished_at) }})
                </p>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                    >{{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                    {{ formatTime(item.finished_at) }})</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="font-size-14 pl-2 text-ellipsis pb-1"
                    >{{ item.title }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-autocomplete
              v-model.trim="filter.engineer"
              hide-details
              :items="engineerList"
              clearable
              dense
              flat
              filled
              label="Engineer"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="closerLoading"
              item-text="display_name"
              item-value="id"
              v-on:change="getTicketCloser"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Engineer(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="$assetAPIURL(item.profile_logo)"
                    aspect-ratio="1"
                    class="margin-auto grey lighten-5 custom-grey-border"
                    transition="fade-transition"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >#{{ item.barcode }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.display_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <v-list-item-subtitle
                    class="text-lowercase pb-2 font-weight-500 font-size-14"
                    >{{ item.primary_email }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="text-lowercase font-weight-500 font-size-14"
                    >{{ item.primary_phone }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Visit Date Range"
                  hide-details
                  solo
                  :disabled="closerLoading"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on:click:clear="clearFilter('dates')"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                color="cyan"
                range
                :disabled="closerLoading"
                v-on:change="getTicketCloser"
                v-model="dates"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="ml-2 text-right">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="closerLoading"
                  v-on:click="getTicketCloser"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="closerLoading"
                  v-on:click="getTicketCloser"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reload</span>
            </v-tooltip>
            <template v-if="false && getPermission('closer:create')">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 custom-bold-button white--text"
                    color="cyan"
                    tile
                    :disabled="closerLoading"
                    v-on:click="addcloserDialog = true"
                  >
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Create Closer</span>
              </v-tooltip>
            </template>
          </div>
        </v-layout>
      </v-container>
      <v-skeleton-loader
        type="text@10"
        class="custom-skeleton table-rows-text"
        v-if="closerLoading"
      ></v-skeleton-loader>
      <v-simple-table
        v-else
        class="inner-simple-table custom-border-top"
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr class="custom-border-bottom">
              <td class="font-size-16 font-weight-700" width="100">#</td>
              <td class="font-size-16 font-weight-700" width="200">
                Timesheet #
              </td>
              <td class="font-size-16 font-weight-700" v-if="!isVisitDetail">
                Engineer
              </td>
              <td class="font-size-16 font-weight-700" v-if="!isVisitDetail">
                Visit
              </td>
              <td class="font-size-16 font-weight-700">Closer Remark</td>
              <!-- <td
                class="font-size-16 font-weight-700"
                width="250"
                v-if="visitDetail?.quotation_relation?.type == 1"
              >
                SDO Signature
              </td> -->
            </tr>
          </thead>
          <tbody>
            <template v-if="lodash.isEmpty(ticketClosers) === false">
              <template v-for="(row, index) in ticketClosers">
                <v-hover v-slot="{ hover }" :key="index">
                  <tr
                    link
                    :key="'ticket-closer-row' + index"
                    :class="{
                      'ticket-closer-row-hover': hover,
                      'custom-border-bottom': ticketClosers[index + 1],
                    }"
                    v-on:click="detailTicketCloser(row)"
                  >
                    <td class="font-size-16 font-weight-500" width="100">
                      <Barcode :barcode="row.barcode"></Barcode>
                    </td>
                    <td class="font-size-16 font-weight-500" width="200">
                      <span v-if="row.timesheet">
                        <Barcode :barcode="row.timesheet.barcode"></Barcode>
                      </span>
                    </td>
                    <td
                      class="font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <v-list-item-avatar size="50" v-if="row.engineer">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              v-bind="attrs"
                              v-on="on"
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(row.engineer.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto white--background"
                              transition="fade-transition"
                            ></v-img>
                          </template>
                          <span>{{ row.engineer.display_name }}</span>
                        </v-tooltip>
                      </v-list-item-avatar>
                    </td>
                    <td
                      class="font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <template v-if="row.visit">
                        <Barcode :barcode="row.visit.barcode"></Barcode>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ formatDateTime(row.visit.started_at) }} -
                          {{ formatTime(row.visit.finished_at) }}
                        </p>
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td class="font-size-16 font-weight-500">
                      <p
                        v-if="row.closer_remark"
                        class="m-0 custom-nowrap-ellipsis max-width-400px"
                      >
                        {{ row.closer_remark }}
                      </p>
                      <em v-else class="text--secondary">No Closer Remark</em>
                    </td>
                    <!--  <td
                      class="font-size-16 font-weight-500"
                      v-if="visitDetail?.quotation_relation?.type == 1"
                    >
                      <p class="m-0 custom-nowrap-ellipsis max-width-400px">
                        <template v-if="row?.sign == 1">
                          <v-chip
                            label
                            small
                            color="red darken-4 white--text"
                            text-color="white"
                            class="text-uppercase font-weight-600"
                            >Signed
                          </v-chip>
                        </template>
                        <template v-else>
                          <v-btn
                            color="red"
                            icon
                            v-on:click.stop.prevent="openSignature(row)"
                          >
                            <v-icon>mdi-signature-freehand</v-icon>
                          </v-btn>
                        </template>
                      </p>
                    </td> -->
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else>
              <td :colspan="isVisitDetail ? 3 : 5" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no closer at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <template v-if="getPermission('closer:view')">
      <template v-if="closerDialog">
        <CloserDetail
          :closerDialog="closerDialog"
          :detail="detail"
          :closer="closer"
          :pageLoading="pageLoading"
          :customer="customer"
          :property="property"
          :billing="billing"
          :contactPerson="contactPerson"
          v-on:close-dialog="closerDialog = false"
        ></CloserDetail>
      </template>
    </template>
    <template v-if="getPermission('closer:create')">
      <template v-if="addcloserDialog">
        <CreateTimeCloser
          :isVisitDetail="isVisitDetail"
          :visitDetail="visitDetail"
          :closerDialog="addcloserDialog"
          :detail="detail"
          v-on:create:ticket-closer="createdTimeCloser"
          v-on:close-dialog="addcloserDialog = false"
        ></CreateTimeCloser>
      </template>
    </template>
    <Dialog :commonDialog="signatureDialog">
      <template v-slot:title>SDO Signature</template>
      <template v-slot:body>
        <v-container class="ml-4">
          <v-layout>
            <v-flex md6 class="signature-pad" id="signature-pad">
              <div
                ref="customer_signature_div"
                style="width: 320px"
                class="custom-border-grey-dashed signature-pad-wrap"
              >
                <canvas
                  ref="customer_signature"
                  style="width: 320px; height: 150px !important"
                ></canvas>
              </div>

              <div style="position: relative">
                <v-btn
                  style="position: absolute; bottom: -7px; right: 17px"
                  content="Click here to clear signature"
                  v-tippy="{ arrow: true, placement: 'top' }"
                  icon
                  small
                  v-on:click="customer_signature.clear()"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </div>
              <!-- <v-img
                contain
                v-if="attachment_url"
                :lazy-src="$defaultImage"
                :src="attachment_url"
              ></v-img>
              <v-img
                v-else
                contain
                :lazy-src="$defaultImage"
                :src="$defaultImage"
              ></v-img>
              <br />
              <v-btn
                depressed
                v-on:click="select_file()"
                color="cyan white--text"
                class="custom-bold-button"
                label
                >Change</v-btn
              > -->
              <v-file-input
                ref="select-file"
                class="d-none"
                accept="image/png, image/jpeg, image/jpg"
                v-model="signature"
                dense
                v-on:change="on_file_change($event)"
                hide-details
              ></v-file-input>
            </v-flex>
            <v-flex md6>
              <div>
                <label for="" class="font-weight-600 mb-0">
                  Company Stamp
                </label>
                <FileCloserTemplate
                  module-type="closer"
                  allowUpload
                  isMinDisplay
                  v-on:file:updated="updateImages"
                  :file-type="true"
                  acceptFile="image/png, image/jpeg, image/jpg"
                ></FileCloserTemplate>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          v-on:click="submitSignature"
          color="cyan white--text"
          class="custom-bold-button"
          :disabled="valid_signature || signatureDialogLoading"
          >Submit</v-btn
        >
        <v-btn
          depressed
          class="mx-2 custom-grey-border custom-bold-button"
          :disabled="signatureDialogLoading"
          @click="signatureDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import CloserDetail from "@/view/pages/job/partials/Closer-Detail.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import Dialog from "@/view/pages/partials/Dialog";
import SignaturePad from "signature_pad";
import { UPLOAD, PUT } from "@/core/services/store/request.module";
import FileCloserTemplate from "@/view/pages/partials/FileCloser";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visitDetail: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addcloserDialog: false,
      closerDialog: false,
      closer: {},
      ticketClosers: [],
      engineerList: [],
      dates: [],
      visitList: [],
      filter: {
        search: null,
        engineer: 0,
        visit: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      job: 0,
      closerLoading: false,
      signatureDialog: false,
      signatureDialogLoading: false,
      signature: null,
      customer_signature: null,
      attachment_url: null,
      companyStamp: [],
      validationErrorBag: [],
      closerId: 0,
    };
  },
  components: {
    Barcode,
    CloserDetail,
    CreateTimeCloser,
    Dialog,

    FileCloserTemplate,
  },
  watch: {
    visitDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.filter.visit = this.lodash.toSafeInteger(param.id);
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getTicketCloser();
        this.getEngineerList();
        this.getVisitList();
      },
    },
    signatureDialog(param) {
      if (param) {
        let _this = this;
        setTimeout(function () {
          _this.initSignature();
        }, 500);
      }
    },
  },
  methods: {
    openSignature(data) {
      this.companyStamp = [];
      this.closerId = data.id;
      this.signatureDialog = true;
    },
    updateImages(param) {
      this.companyStamp = param;
    },
    submitSignature() {
      const _this = this;

      if (_this.customer_signature.isEmpty()) {
        return false;
      }
      _this.validationErrorBag = new Array();
      this.signatureDialogLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: `closer/${this.closerId}/signature/update`,
          data: {
            signature: _this.customer_signature.toDataURL(),
            attachment: this.companyStamp,
          },
        })
        .then(() => {
          _this.getTicketCloser();
          _this.customer_signature.clear();
          this.signatureDialog = false;
          this.signatureDialogLoading = false;
          this.$emit("load:visit", true);

          /* this.getUser(); */
        })
        .catch(() => {
          this.signatureDialogLoading = false;
        })
        .finally(() => {
          _this.customer_signature.clear();
        });
    },
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = URL.createObjectURL(file);
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    initSignature() {
      const _this = this;
      if (_this.signatureDialog) {
        _this.$nextTick(() => {
          let ccanvas = _this.$refs["customer_signature"];
          let ccanvasDiv = _this.$refs["customer_signature_div"];
          // let cparentDiv = document.getElementById("signature-pad");
          let cparentWidth = 320; //cparentDiv.offsetWidth / 2;

          ccanvas.setAttribute("width", cparentWidth);

          ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");

          _this.customer_signature = new SignaturePad(ccanvas);
        });
      }
    },
    detailTicketCloser(row) {
      this.closerDialog = true;
      this.closer = row;
    },

    createdTimeCloser() {
      this.addcloserDialog = false;
      this.getTicketCloser();
      DialogJobEventBus.$emit("load:job", true);
    },
    getTicketCloser() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        visit: _this.lodash.toSafeInteger(_this.filter.visit),
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        daterange: _this.dates,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      clearTimeout(_this.timeout);
      _this.closerLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getClosers(_this.job, { filter })
          .then((response) => {
            _this.ticketClosers = response;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.closerLoading = false;
          });
      }, _this.timeoutLimit);
    },
    getEngineerList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getEngineers(_this.job)
        .then(({ rows }) => {
          if (_this.lodash.isEmpty(rows) === false) {
            _this.engineerList = rows.map((row) => {
              return row.engineer;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getVisitList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getVisitsForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getTicketCloser();
    },
  },
  mounted() {
    this.getTicketCloser();
    this.getEngineerList();
    this.getVisitList();
    this.initSignature();
  },
  computed: {
    valid_signature() {
      return this.customer_signature
        ? this.customer_signature.isEmpty()
        : false;
    },
  },
};
</script>
